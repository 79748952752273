// websocket实时通讯
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;
// 发送消息
export const SendMessage= (params) => {
  return service({
    url: `${baseUrl}/trade-website/online/send`,
    method: 'post',
    data: params,
  })
}
//查询待办任务列表
export const selectRunTaskList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/selectRunTaskList`,
    method: 'get',
    params:params,
  })
}

//查询已办任务列表
export const selectHistoryProcess = (params) => {
  return service({
    url: `${baseUrl}/trade-website/startEvent/selectHistoryProcess`,
    method: 'get',
    params:params,
  })
}

//查询待办任务详情办理
export const selectCirculationApproval = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectCirculationApproval`,
    method: 'get',
    params:params,
  })
}