<template>
  <div>
    <el-dialog
      title="资源资产流转审批"
      :visible.sync="dialogVisible"
      width="100%"
      center
      :before-close="handleClose"
    >
      <!-- 项目详情 -->
      <div class="project-detail">
        <div class="define-table">
          <table style="table-layout: fixed; border: 1px">
            <tr>
              <td rowspan="2">流入方</td>
              <td>流入方</td>
              <td>{{ projectInfo.lrf }}</td>
              <td>单位性质</td>
              <td colspan="3">{{ projectInfo.res }}</td>
            </tr>
            <tr>
              <td>法定代表人</td>
              <td>{{ projectInfo.bossName }}</td>
              <td>身份证号</td>
              <td>{{ projectInfo.idCard }}</td>
              <td>联系方式</td>
              <td>{{ projectInfo.phone }}</td>
            </tr>
            <tr>
              <td rowspan="2">流转信息汇总</td>
              <td>总面积</td>
              <td>{{ projectInfo.sumArea }}亩</td>
              <td>成交总金额</td>
              <td colspan="3">{{ projectInfo.sumPrice }}元</td>
            </tr>
            <tr>
              <td>农地性质</td>
              <td colspan="5">
                {{ cqlx(projectInfo.cqlxbh) }}
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox label="耕地"></el-checkbox>
                  <el-checkbox label="园地"></el-checkbox>
                  <el-checkbox label="养殖水地"></el-checkbox>
                  <el-checkbox label="禽畜饲养用地" disabled></el-checkbox>
                  <el-checkbox label="设施农业用地" disabled></el-checkbox>
                  <el-checkbox label="四荒地" disabled></el-checkbox>
                  <el-checkbox label="其他" disabled></el-checkbox>
                </el-checkbox-group> -->
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="project-detail">
        <h3>流转详情</h3>
        <div
          class="tableTop"
          v-for="(item, index) in projectInfo.lcfList"
          :key="index"
        >
          <table style="table-layout: fixed; border: 1px">
            <tr>
              <td rowspan="2">
                <p>流出方</p>
                <p style="color: #169bd5" @click="HTVisible = true">查看合同</p>
              </td>
              <td>流出方</td>
              <td>{{ item.lcf }}</td>
              <td>家庭住址</td>
              <td colspan="3">{{ item.address }}</td>
            </tr>
            <tr>
              <td>
                <p>法定代表人</p>
                <p>(负责人/农户代表人/)</p>
              </td>
              <td>{{ item.bossName }}</td>
              <td>身份证号</td>
              <td>{{ item.idCard }}</td>
              <td>联系方式</td>
              <td>{{ item.phone }}</td>
            </tr>
            <tr>
              <td rowspan="7">流转内容及方式</td>
              <td>所有权人</td>
              <td colspan="5">{{ item.owner }}</td>
            </tr>
            <tr>
              <td>坐落位置</td>
              <td colspan="5">{{ item.location }}</td>
            </tr>
            <tr>
              <td>农地权属</td>
              <td colspan="5">
                {{ item.landType }}
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox label="国有"></el-checkbox>
                  <el-checkbox label="集体所有"></el-checkbox>
                </el-checkbox-group> -->
              </td>
            </tr>
            <tr>
              <td>农地性质</td>
              <td colspan="5">
                {{ cqlx(item.cqlxbh) }}
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox label="耕地"></el-checkbox>
                  <el-checkbox label="园地"></el-checkbox>
                  <el-checkbox label="养殖水地"></el-checkbox>
                  <el-checkbox label="禽畜饲养用地" disabled></el-checkbox>
                  <el-checkbox label="设施农业用地" disabled></el-checkbox>
                  <el-checkbox label="四荒地" disabled></el-checkbox>
                  <el-checkbox label="其他" disabled></el-checkbox>
                </el-checkbox-group> -->
              </td>
            </tr>
            <tr>
              <td>流转面积</td>
              <td colspan="2">{{ item.flowArea }}亩</td>
              <td>成交金额</td>
              <td colspan="2">{{ item.cjPrice }}元</td>
            </tr>
            <tr>
              <td>合同签署日期</td>
              <td colspan="2">{{ item.contractSignDate }}</td>
              <td>合同编号</td>
              <td colspan="2">{{ item.contractCode }}</td>
            </tr>
            <tr>
              <td>合同起止日期</td>
              <td colspan="5">
                {{ item.contractStartDate }}
                <!-- <el-form>
                  <el-form-item>
                    <el-date-picker
                      v-model="time"
                      placeholder="选择开始日期"
                      size="small"
                      value-format="yyyy-MM-dd"
                      :clearable="false"
                      @change="changeTime"
                    >
                    </el-date-picker> -->
                至
                {{ item.contractEndDate }}
                <!-- <el-date-picker
                      v-model="time1"
                      placeholder="选择结束日期"
                      align="right"
                      size="small"
                      value-format="yyyy-MM-dd"
                      :clearable="false"
                      @change="changeTime1"
                    >
                    </el-date-picker>
                        </el-form-item>
                </el-form>
                     -->
                ,共计{{ item.lznx }}年
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="bottomBtn" v-if="transact">
        <div class="backBtn" @click="tuihui">退回</div>
        <div class="submitBtn" @click="tongyi">符合规定，同意流转</div>
      </div>
    </el-dialog>
    <!-- 查看合同弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="HTVisible"
      width="30%"
      z-index="10000"
      :before-close="handleClose"
    >
      <!-- 展示上传的图片 -->
      <div>
        <div class="tabs">
          <div v-for="(item, index) in tabList" :key="index">
            <span @click="detailBtn(index)">{{ item.title }}</span>
          </div>
          <!-- <div v-for="(item,index) in 5" :key="index">
            {{ index }}
          </div> -->
        </div>
        <div class="ShowList" v-if="fileList.length">
          <div class="innerTable">
            <el-upload
              v-if="innerVisible"
              class="upload-demo"
              action=""
              :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG, WebP,'"
              list-type="picture-card"
              :file-list="fileList"
              multiple
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <el-image
                  ref="preview"
                  style="height: 115px"
                  class="el-upload-list__item-thumbnail"
                  :src="file.imgUrl"
                  :preview-src-list="innerTableList"
                  alt=""
                  :initial-index="0"
                />
                <span>{{ file.FilesName }}</span>
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!file.isShow"
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <!-- <span
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span> -->
                </span>
              </div>
            </el-upload>
          </div>
        </div>
        <div v-else style="width: 100%; text-align: center">暂无数据</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="HTVisible = false">取 消</el-button>
        <el-button type="primary" @click="HTVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="审批不通过"
      :visible.sync="spshow"
      width="30%"
      append-to-body
    >
      <!-- 展示上传的图片 -->
      <div class="tuihui">
        <div class="leftbox">退回说明</div>
        <div class="rightbox">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="title"
          >
          </el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="spshow = false">取 消</el-button>
        <el-button type="primary" @click="spshow = false">退 回</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="审批通过"
      :visible.sync="tyshow"
      width="20%"
      append-to-body
    >
      <!-- 展示上传的图片 -->
      <div class="tongyi">
        <div class="topbox">
          <span>审批意见：</span> <input type="text" v-model="idea">
        </div>
        <div class="footerbox">
         <img src="../../../assets/acquiesce.png" alt="加载失败">
         <h1 style="color: #ccc;">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer" >
        <el-button  type="primary" @click="tyshow = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { selectCirculationApproval } from "@/api/spgl";
import { log } from "console";
import { getLzlx, getCqlx } from "../../../util/types";
import { title } from "process";
export default {
  data() {
    return {
      taskId: "", // 查询id
      tabList: [
        {
          title: "合同归档文件",
          index: "1",
        },
        {
          title: "合同备案照片",
          index: "2",
        },
      ],
      time: "",
      time1: "",
      title: "", // 审批退回不通过陈述
      idea:'', // 审批通过意见
      dialogVisible: true,
      spshow: false, // 退回弹窗
      tyshow: false, // 同意弹窗
      projectInfo: {}, // 详情信息
      // checkList: ["选中且禁用", "复选框 A"],
      HTVisible: false,
      baseUrl: window.globalUrl.BASE_API,
      fileList: [],
      transact:false
    };
  },
  computed: mapGetters(["userInfo", "access_token", "identityObj"]),
  methods: {
    // 接收参数
    receiveData(data,Boolean) {
      console.log(data);
      this.taskId = data.processId;
      this.transact=Boolean
      this.details();
    },
    // 详情
    async details() {
      let res = await selectCirculationApproval({
        // taskId:this.taskId
        taskId: "d5dd5c6e-50a3-11ef-b412-005056c00008",
      });
      this.projectInfo = res.data;
      console.log(res);
    },
    lzlx(type) {
      let res = getLzlx(type);
      return res;
    },
    cqlx(type) {
      console.log("type", type);
      let list = JSON.parse(localStorage.getItem("types"));
      let res = "";
      console.log(list);
      if (list && list.length > 0) {
        // list.forEach((x) => {
        //   console.log("x",x);
        //   if (x.bh == type) {
        //     res = x.name;
        //   }
        // });
        let res = getCqlx(type, list);
        console.log(res);
        return res;
      } else {
        return "";
      }
    },

    handleClose() {
      this.$emit("closeDialog", false);
    },
    // 退回
    tuihui() {
      this.spshow = true;
    },
    // 同意
    tongyi() {
      this.tyshow = true;
    },

    // 关闭弹框
    closeDialog() {
      this.isShow = false;
    },
    // 切换时间
    changeTime(val) {
      console.log(val);
      // if (val == 1) {
      this.startTime = val;
      // } else {
      //   this.endTime = val[1];
      // }
    },
    // 切换时间
    changeTime1(val) {
      console.log(val);
      // if (val == 1) {
      //   this.startTime = val[0];
      // } else {
      this.endTime = val;
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.tongyi{
  box-sizing: border-box;
  .topbox{
    span{
      width: 30%;
      font-size: 24px;
      font-weight: 400;
      color: #000;
    }
    input{
      width: 60%;
    }
  }
  
  .footerbox{
      width: 100%;
      text-align: center;
      img{
        width: 80%;
        height: 80%;
      }
    }
}
.tuihui {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #000;
  .leftbox {
    width: 20%;
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 24px;
    border-right: 1px solid #000;
  }
  .rightbox {
    width: 80%;
    height: 200px;

    // border-right: 1px solid #000;
  }
}
.project-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 50px;

  .define-tableDetails {
    margin-top: 50rpx;
  }
  table {
    width: 100%;

    border-collapse: collapse; /* 合并线段 */
  }

  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
  .tableTop {
    padding-top: 10px;
  }
}
.bottomBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .backBtn {
    width: 100px;
    height: 40px;
    color: #fff;
    background: #f7c6cc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }
  .submitBtn {
    width: 150px;
    height: 40px;
    display: flex;
    color: #fff;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    background: #02a7f0;
    border-radius: 50px;
  }
}
.tabs {
  width: 100%;
  height: 100rpx;
  display: flex;
  margin: 20px 0px;

  span {
    margin-right: 20px;
    border: solid 2px #ccc;
    padding: 5px 5px;
    cursor: pointer;
  }
}
.ShowList {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    tr {
      td {
        .box {
          width: 150px;
          height: 100px;
          overflow: hidden;
          padding: 5px 5px;
          .Imgs {
            width: 232px;
            .el-image {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
